import React from "react";
import styles from '../../styles/xmlraw.module.css';

export function HTMLViewer({ base64data })
{
  return (
    <div className={styles.textViewer}>
      <iframe
        title='document'
        src={`data:text/html;base64,${base64data}`}
      >
        The “iframe” tag is not supported by your browser.
      </iframe>
    </div>
  );
}
