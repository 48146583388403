import React, { Component } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Restore,
  ZoomIn,
  ZoomOut,
  //ZoomOutMap
} from '@material-ui/icons'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import FileActionsBar from '../FileActionsBar/FileActionsBar'
import colors from '../../utils/colors'
import styles from '../../styles/viewer.module.css'

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        margin: '0 5px',
        width: '25px'
      }
    }
  },
})

export default class ImageViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rotation: 0
    }
  }

  rotateRight = () => {
    let newRotation = this.state.rotation + 90;
    console.log('ROTATION', newRotation)
    if(newRotation >= 360) {
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotateLeft = () => {
    let newRotation = this.state.rotation - 90;
    if(newRotation >= 360) {
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const {
      fileData,
      fileType,
      handleBack,
      handlePrint
    } = this.props
    const { rotation } = this.state

    return(
      <ThemeProvider theme={theme}>
        <div className={styles.viewer}>
          <FileActionsBar
            fileType={fileType}
            fileData={fileData}
            handleBack={handleBack}
            handleLeft={this.rotateLeft}
            handlePrint={handlePrint}
            handleRight={this.rotateRight}
          />
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
            limitToWrapper={true}
          >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className={styles.zoomTools}>
                <ZoomIn onClick={zoomIn} />
                <Restore onClick={resetTransform} />
                <ZoomOut onClick={zoomOut} />
              </div>
              <div className={styles.imgContainer}>
                <TransformComponent className={styles.imgContainer}>
                  <img
                    alt="img"
                    className={styles.imgViewer}
                    src={`data:image/jpeg;base64,${fileData}`}
                    style={{transform: `rotate(${rotation}deg)`}}
                  />
                </TransformComponent>
              </div>
            </React.Fragment>
          )}
          </TransformWrapper>
        </div>
      </ThemeProvider>
    )
  }
}
