// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n  --padding: 10px;\n  --searchBoxHeight: 60px;\n}\n\n.xmlraw_xmlViewerRaw__2r_Aj {\n  padding: var(--padding);\n  overflow: auto;\n  margin-top: 10px;\n  height: calc(100vh - 474px);\n  font-family: monospace;\n  white-space: pre;\n}\n\n.xmlraw_textViewer__18EEe {\n  display: flex;\n  flex-direction: column;\n  padding: var(--padding);\n  height: calc(100vh - 420px);\n}\n\n.xmlraw_textViewer__18EEe textarea {\n  font-family: monospace;\n  resize: none;\n  border: none;\n  height: 100%;\n}\n\n.xmlraw_textViewer__18EEe iframe {\n  resize: none;\n  border: none;\n  height: 100%;\n}\n\n.xmlraw_highlight__33lnn {\n  color: red;\n  font-weight: bold;\n  background-color: yellow;\n}\n\n.xmlraw_foundOccurrences__3E9gr {\n  padding: 2px 5px;\n  color: green;\n  font-weight: bold;\n  background-color: yellow;\n}\n\n.xmlraw_show__3HjDD {\n  display: inline-block;\n}\n\n.xmlraw_hide__2DBtW {\n  display: none;\n}\n\n.xmlraw_searchBox__2yfR_ {\n  width: calc(100% - var(--padding) * 2);\n  padding: 3px;\n  position: relative;\n  top: var(--padding);\n  left: var(--padding);\n}\n\n.xmlraw_searchBoxContainer__29cMH {\n  min-height: var(--searchBoxHeight);\n}\n\n.xmlraw_searchBoxGrow__2_NnR {\n  padding-bottom: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"xmlViewerRaw": "xmlraw_xmlViewerRaw__2r_Aj",
	"textViewer": "xmlraw_textViewer__18EEe",
	"highlight": "xmlraw_highlight__33lnn",
	"foundOccurrences": "xmlraw_foundOccurrences__3E9gr",
	"show": "xmlraw_show__3HjDD",
	"hide": "xmlraw_hide__2DBtW",
	"searchBox": "xmlraw_searchBox__2yfR_",
	"searchBoxContainer": "xmlraw_searchBoxContainer__29cMH",
	"searchBoxGrow": "xmlraw_searchBoxGrow__2_NnR"
};
module.exports = exports;
