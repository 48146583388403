export function calculateContentHeight() {
    return window.innerHeight - 342;
}
  
export function formatJson(base64FileData) {
    if (!base64FileData) {
        return '';
    }
    const fileData = atob(base64FileData);
    try {
        const jsonObj = JSON.parse(fileData);
        return JSON.stringify(jsonObj, null, 2)
    }
    catch (error) {
        return fileData;
    }
}
  
const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
const FORMAT_PDF = ['pdf'];
const FORMAT_OTHER = ['xml','plain'];
const FORMAT_HTML = ['html', 'htm'];
const FORMAT_JSON = ['json','vnd.patientholistics.assess.response.json'];
  
export function getMimeType(mime) {
    return (mime && mime.split('/').pop()) || '';
}
  
export function getFormatViewer(contentType) {
    let viewerType;

    switch (contentType.includes(getMimeType(contentType))) {
        case FORMAT_IMG.includes(getMimeType(contentType)):
            viewerType = 'file-image';
        break;
        case FORMAT_PDF.includes(getMimeType(contentType)):
            viewerType = 'file-pdf';
        break;
        case FORMAT_HTML.includes(getMimeType(contentType)):
            viewerType = 'file-html';
        break;
        case FORMAT_OTHER.includes(getMimeType(contentType)):
            viewerType = 'file-code';
        break;
        case FORMAT_JSON.includes(getMimeType(contentType)):
            viewerType = 'file-json';
        break;
        default:
            viewerType = 'unknown';
    }

    return viewerType;
}