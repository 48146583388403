import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { GetApp } from '@material-ui/icons'
import colors from '../../utils/colors'
import { formatJson } from '../../utils/viewer'

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        marginRight: '70px',
        right: '0',
        top: '153px',
        position: 'absolute',
        width: '25px'
      }
    }
  }
})

const DownloadButton = ({
  fileData,
  fileType,
}) => {
  if(fileType === 'file-pdf') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:application/pdf;base64,${fileData}`} download="file.pdf">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-image') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:image/jpeg;base64,${fileData}`} download="file.jpeg">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-code') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:text/xml;base64,${fileData}`} download="file.xml">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-json') {
    return(
      <ThemeProvider theme={theme}>
        <a href={"data:text/json;charset=utf-8," + encodeURIComponent(formatJson(fileData))} download="file.json">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-html') {
    return(
      <ThemeProvider theme={theme}>
        <a href={"data:text/html;base64," + encodeURIComponent(fileData)} download="file.html">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
}

export default DownloadButton
