import _ from 'lodash';
import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import DownloadButton from './FileActionsBar/DownloadButton'
import PrintButton from './FileActionsBar/PrintButton'
import ImageViewer from './Viewers/ImageViewer'
import NotAvailableViewer from './Viewers/NotAvailableViewer'
import { HTMLViewer } from './Viewers/HTMLViewer'
import styles from '../styles/viewer.module.css'
import { Container, CircularProgress } from '@material-ui/core';
import { formatJson, calculateContentHeight, getFormatViewer } from '../utils/viewer';

const KNOWN_FILE_TYPES = ['file-pdf', 'file-image', 'file-code', 'file-html', 'file-json'];

const theme = createTheme({
  overrides: {
  },
})

const FileViewer = ({
  fileContentType,
  fileData,
  fileType,
  handleBack,
}) => {
  const [contentHeight, setContentHeight] = useState(calculateContentHeight());
  const [loading, setLoading] = useState(false);
  const isUnknownFileType = !KNOWN_FILE_TYPES.includes(fileType);

  const handlePrint = () => {
    const winparams = 'dependent=yes,locationbar=no,scrollbars=yeenubar=yes,'+
                'resizable,screenX=50,screenY=50,width=850,height=1050'
    const htmlPop = '<embed width=100% height=100%'
                         + ` type="${fileContentType}"`
                         + ` src="data:${fileContentType};base64,`
                         + escape(fileData)
                         + '"></embed>'

    const printWindow = window.open ("", "PDF", winparams)
    printWindow.document.write(htmlPop)
    printWindow.document.close()
    printWindow.focus()
    printWindow.print()
  }

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.centeredProgress}>
          <CircularProgress/>
        </Container>
      </ThemeProvider>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
      {getFormatViewer(fileContentType) === 'file-pdf' &&
        <div className={styles.viewer}>
          <div className={styles.documentContainer}>
            <iframe
              title="Patient Document"
              style={{ width: '100%', height: '100%' }}
              src={`data:application/pdf;base64,${fileData}`}
            />
          </div>
        </div>
      }
      {getFormatViewer(fileContentType) === 'file-image' &&
        <ImageViewer
          fileData={fileData}
          fileType={'file-image'}
          handleBack={handleBack}
          handlePrint={handlePrint}
        />
      }
      {getFormatViewer(fileContentType) === 'file-code' &&
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: 'relative', top: '-140px' }}>
              <DownloadButton fileData={fileData} fileType={'file-code'} />
              <PrintButton handlePrint={handlePrint} />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            <HTMLViewer base64data={fileData} />
          </div>
        </div>
      }
      {getFormatViewer(fileContentType) === 'file-html' &&
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: 'relative', top: '-140px' }}>
              <DownloadButton fileData={fileData} fileType={'file-html'} />
              <PrintButton handlePrint={handlePrint} />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            <HTMLViewer base64data={fileData} />
          </div>
        </div>
      }
      {getFormatViewer(fileContentType) === 'file-json' &&
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: 'relative', top: '-140px' }}>
              <DownloadButton fileData={fileData} fileType={'file-json'} />
              <PrintButton handlePrint={handlePrint} />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            <div style={{ overflow:'scroll', height: `${contentHeight}px`, width:'100%' }}>
              <pre>{formatJson(fileData)}</pre>
            </div>
          </div>
        </div>
      }
      {getFormatViewer(fileContentType) === 'unknown' &&
        <NotAvailableViewer
          fileContentType={fileContentType}
          fileData={fileData}
          fileType={fileType}
        />
      }
    </ThemeProvider>
  )
}

export default FileViewer
