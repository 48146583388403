import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Print } from '@material-ui/icons'
import colors from '../../utils/colors'

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        marginRight: '30px',
        right: '0',
        top: '153px',
        position: 'absolute',
        width: '25px'
      }
    }
  }
})

const PrintButton = ({
  handlePrint
}) => {
  return(
    <ThemeProvider theme={theme}>
      <Print onClick={handlePrint} />
    </ThemeProvider>
  )
}

export default PrintButton
