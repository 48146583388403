import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import './App.css';
import FileViewer from './file_viewer/FileViewer';
import React, { useEffect, useState } from 'react';
import { Container, CircularProgress } from '@material-ui/core';
import styles from './styles/viewer.module.css'

const theme = createTheme({
  overrides: {
  },
})

function App() {
  const [document, setDocument] = useState(null)
  
  const isOriginAllowed=(originUrl)=>{
    //TODO validate against db listo of allowed origins
    return originUrl!='http://localhost:3000';
  }

  useEffect(() => {
    window.addEventListener('message',(e)=>{ //Messager received from any window
      if(isOriginAllowed(e.origin) && e.data && e.data.topic=='RenderDocument'){//Validate allowed windows and topic is correct
        setDocument(e.data.document);
      }
    });
    if(window.parent) //Send ready message to parent so that it can send the document
      window.parent.postMessage({topic:'Ready'},'*');
  },[]);

  return (
    <div className="App">
      {!document &&
        <ThemeProvider theme={theme}>
          <Container className={styles.centeredProgress}>
            <CircularProgress/>
          </Container>
        </ThemeProvider>
      }
      {document &&
        <FileViewer
          fileContentType={document.metadata.contentType}
          fileData={document.dataBase64}
          fileType={document.dataType}
          handleBack={document.closeDocument}
          isEmptyDocument={document.emptyDocument}
          fhirDocument={document.fhirDocument}
        />
      }
    </div>
  );
}

export default App;
